$primary:#9a9a9a;
$border:#9a9a9a;



$theme-colors:(
"primary":$primary,
"border":$border
);




@import 'react-big-calendar/lib/sass/styles';
/* The following line can be included in a src/App.scss */
@import '~bootstrap/scss/bootstrap';

@tailwind base;
@tailwind components;
@tailwind utilities;

.alkatra-Brand {
  font-family: "Alkatra", system-ui;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}


.w-30 {
    width: 30%;
  }
  .w-84 {
    width: 84%;
  }
  .w-73 {
    width: 73%;
  }
  .w-92{
    width: 92%;
  }
  .bg-alt{
    background-color: #e6e6e6;
  }
  .bg-nav{
    background-color: #9595ff0d;
  }
  .bg-activeNav{
    background-color: #6eaec2;
  }
  .textHeighlight{
    color: #f97316;
  }
  .BgColorHover:hover {
    background-color: #8989ff21 /* Your desired background color */;
    /* Optionally, you can change other properties like text color, border color, etc. */
  }
.rbc-calendar{
  width: 60%;
  height: 80vh;
}

.rbc-toolbar button.rbc-active{
  background: #20994d;
  color: white;
}
.rbc-toolbar{
  background: #9480dd96;
  padding: 4px;
  border-radius: 4px;
}
.rbc-event{
  background: #9480dd96;
}
@media only screen and (max-width: 600px){
  .rbc-calendar{
    width: 100%;
    height: 80vh;
  }
}

// .bg-custom-purple {
//     background-color: rgb(34, 9, 44);
//   }
  