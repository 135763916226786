.App {
  text-align: center;
}

.inria-sans-light {
  font-family: "Inria Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.inria-sans-regular {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inria-sans-bold {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.chatPage{
  height: 54vh;
  width: 400px;
  
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  animation: fadeInUp 1s ease-out forwards;
}

.hero-img {
  opacity: 0; /* Initially hidden */
  transform: translateY(100px); /* Initial position for animation */
}

.bgHero{
  /* background: radial-gradient(circle, rgba(181, 175, 204, 0.9) 50%, rgba(255, 255, 255, 0.973) 20%,  rgba(0, 255, 42, 0.973) 10%); */
background-color: #fff;
}
.bgHeader{
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(175, 172, 172, 0.664);
  padding: 8px 24px;
}
.bgPrimaryButton{
  background: #20994d;
}

.bgSec2{
  background:#fff;
  /* #9480dd96; */
  /* background: linear-gradient(304deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 45%);  */
}

.bgFeature{
  /* background: rgb(238,174,202);
  ba */

  background-color: rgba(255, 255, 255, 0.74);
  /* background: linear-gradient(14deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);  */
}
.cta{
  background: #0066ff;
  padding: 15px 30px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: white;
  /* width: fit-content; */
  border-radius: 8px;
  /* box-shadow: 2px 2px 2px 2px gray; */

}

.cta:hover{
  box-shadow: none;
}
.textSecondary{
  color: #0066ff;;
}
.textPrimary{
  color: #7d60e3;;
}
.bgPrimary{
  background: #9480dd96;
}

.bgprimary6{
  background-color: #ab93ff79;
}
.bgSecondary{
  background: #fff;
}
.bgAppPrimary{
  background: #fff;
}
.bgAppSecondary{
  background: #fff;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enter animation */
.animateBgMobileEnter {
  /* Required for the 'left' property to work */
  animation-name: animateBgEnter;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  visibility: visible; /* Ensure the element is visible when entering */
  animation-fill-mode: forwards; /* Keeps the final state after the animation ends */
}

/* Keyframes for entering */
@keyframes animateBgEnter {
  0% {
    left: -800px;
  }
  100% {
    left: 0px;
  }
}

/* Exit animation */
.animateBgMobileExit {
/* Required for the 'left' property to work */
  animation-name: animateBgExit;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards; /* Keeps the final state after the animation ends */
  visibility: hidden;
}

/* Keyframes for exiting */
@keyframes animateBgExit {
  0% {
    left: 0px;
    visibility: visible; /* Ensure the element is visible at the start */
  }
  50% {
    left: -400px;
    visibility: visible; /* Keep it visible during the animation */
  }
  100% {
    left: -800px;
    visibility: hidden; /* Hide the element at the end of the animation */
  }
}


.lineThin{
 border-bottom: 1px solid gray;
 width: 100%;
}


 .smIcons{
  /* bottom:200px; */
  display: block;
    animation: fadeUp 3s ease-out forwards;
    
    
 
 
}
.MuiFormControl-root{
  max-width: 300px;
}


.featureEach:hover{
  background: #aea1df63;
}
.rbc-active button{
  background: #9480dd96;
}